import React, {useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useParams} from "react-router-dom";
import wordCheck from "../../../functions/wordCheck";
import SuccessCheck from "../../shared/SuccessCheck";
import {useMotionValue, motion} from "framer-motion";

export default function WordcloudQuestion({sendAnswer, success, answered, isFixedOpts = false}){
    const [word, setWord] = useState('')
    const [spazio, setSpazio] = useState(false)

    let progress = useMotionValue(90)

    const handleSetWord = (event) => {
      setWord(event.target.value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word).trim()

        setSpazio(false)
        /*if(w.includes(' ')){
            setSpazio(true)
        } else {*/
            if(!isFixedOpts) {
                if (wordCheck(w)) {
                    sendAnswer(w)
                    setWord('')
                } else {
                    setWord('')
                }
            } else {
                sendAnswer(w)
                setWord('')
            }
        /*}*/

    }

    return(
        <Stack sx={{position:'relative'}} justifyContent={"start"} alignItems={"start"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {(!success) ?
                !isFixedOpts ?
                    <Box sx={{width: '100%', mt:5}}>
                        <FormControl variant="standard" style={{width: '100%'}}>
                            <TextField
                                inputProps={{style:{fontSize:'1.4rem'}}}
                                placeholder="Inserisci una parola" id="component-simple" value={word} sx={{fontSize:'3rem'}} onChange={handleSetWord}/>
                        </FormControl>
                        {!isFixedOpts && word && word.includes(' ') &&
                            <Typography sx={{mt: 1}}>Puoi inviare solo una parola</Typography>
                        }
                        <Button disabled={word.length<2 || word.includes(' ')} sx={{mt: 4}} variant={'wordcloud'} onClick={handleSendAnswer}>Invia</Button>
                    </Box>
                    :
                    <Box sx={{width: '100%', mt:5}}>
                        <FormControl fullWidth variant={'standard'}>
                            <TextField
                                placeholder="Inserisci una parola" id="component-simple"
                                value={word}
                                onChange={handleSetWord}
                                select
                            >
                                <MenuItem value={'Sasso'}>Sasso</MenuItem>
                                <MenuItem value={'Muschio'}>Muschio</MenuItem>
                                <MenuItem value={'Rosa del deserto'}>Rosa del deserto</MenuItem>
                                <MenuItem value={'Corteccia'}>Corteccia</MenuItem>
                                <MenuItem value={'Roccia'}>Roccia</MenuItem>
                                <MenuItem value={'Pietra'}>Pietra</MenuItem>
                                <MenuItem value={'Ramo'}>Ramo</MenuItem>
                                <MenuItem value={'Conchiglia'}>Conchiglia</MenuItem>
                                <MenuItem value={'Biglia'}>Biglia</MenuItem>
                            </TextField>
                        </FormControl>
                        <Button disabled={!word || word.length<2} sx={{mt: 4}} variant={'wordcloud'} onClick={handleSendAnswer}>Invia</Button>
                    </Box>
                : null
            }
            {(success) &&
                <Box style={{width:'100%'}} paddingX={8}>
                    <motion.div
                        initial={{ x: 0 }}
                        animate={{ x: 100 }}
                        style={{ x: progress }}
                        transition={{ duration: 0.8 }}
                    />
                    <SuccessCheck progress={progress}/>
                </Box>
            }
        </Stack>
    )
}
