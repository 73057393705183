//export const API_URL = "https://plesh-remote-dev-api-y2qbyk5t3a-ew.a.run.app"
export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#002b74', '#d50809'],
        home_question: ['#d50809', 'rgba(3,153,254,0.45)'],
        applausometro: ['#d50809', 'rgba(3,153,254,0.45)'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#d50809', 'rgba(3,153,254,0.45)'],
        qna: ['#d50809', 'rgba(3,153,254,0.45)'],
    },
    primary: '#d50809',
    secondary: '#263238',
    accent: '#d50809',
    text: '#eceff1',
    background:"#001846",
    poll:{
        default:"rgba(3,153,254,0.27)",
        answered:"rgba(233,0,2,0.54)",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}