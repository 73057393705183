import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {
    alpha,
    Box,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import header from "../assets/evento/header.png";
import pleshDark from "../theme/pleshDark";
import {Edit} from "@mui/icons-material";

const LogoBox = ({logo}) => {
    return (
        <Box style={{width: '100%', height: '30%'}} sx={{px: 2}}>
            <img style={{margin: 'auto', width: '100%', height: '100%', objectFit: 'contain'}} src={logo}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, displayName, setDisplayName}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==", true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    const [editDisplayName, setEditDisplayName] = useState(false)

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [displayName]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    function handleEditDisplayName() {
        setEditDisplayName(!editDisplayName)
    }

    return (
        <Box>
            <Box sx={{height: '75vh', py: 1}}>
                {/*<img src={header} width={'100%'}/>*/}
                {
                    event.data().autenticazione && displayName &&
                    <Box display={'flex'} justifyContent={'end'}>
                        <Chip label={displayName} size={'small'} deleteIcon={<Edit/>} onDelete={handleEditDisplayName}/>
                        <Dialog open={editDisplayName} onClose={handleEditDisplayName}>
                            <DialogContent>
                                <DisplayName displayName={displayName} setDisplayName={setDisplayName}/>
                            </DialogContent>
                        </Dialog>
                    </Box>
                }
                <Box sx={{height: '30%'}}></Box>
                {event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                {
                    event.data().autenticazione && !displayName &&
                    <Box pt={'50%'}>
                        <DisplayName displayName={displayName} setDisplayName={setDisplayName}/>
                    </Box>
                }
                { event.data().autenticazione && !!displayName &&
                    <Stack pt={10} pb={3} sx={{width: '85%', margin: 'auto'}}>
                        <Typography fontWeight={'bold'} variant={'h6'}>AGENDA</Typography>
                        {/*<Box height={'1px'} sx={{background: 'white', margin: 'auto', mt: 1}} width={'70%'} />*/}
                        <Grid py={1} container
                              sx={{
                                  margin: 'auto',
                                  px:1,
                                  background: alpha(pleshDark.palette.background.default, 0.5),
                                  borderRadius: '1rem'
                        }}>
                            {
                                agenda.map((event) => (<>
                                        <Grid item xs={6} sm={6} textAlign={'right'}>
                                            <Typography sx={{mr: 1}} fontWeight={'lighter'} fontSize={'small'}>
                                                {event.ore}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <Typography sx={{ml: 1}} fontWeight={'bold'} textAlign={'left'} fontSize={'small'}>
                                                {event.evento}
                                            </Typography>
                                        </Grid>
                                    </>
                                ))
                            }
                        </Grid>
                    </Stack>
                }
                {!pinnedLoading && !pinnedError && pinned ?
                    event.data().autenticazione
                        ?
                        (displayName !== '' && displayName) &&
                        <PinnedQuestions questions={pinned} applause={event.data().applausometro && eventPlaying} user={user}/>
                        :
                        <PinnedQuestions questions={pinned} user={user}/>
                    :
                    <></>
                }
            </Box>
            {/*loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                        ?
                        (displayName !== '' && displayName) &&
                        <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                        :
                        <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                    :
                    <></>
            */}
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}

const agenda = [
    {ore: "16.00 - 16.30", evento: "Accredito"},
    {ore: "16.30 - 19.00", evento: "Plenaria"},
    {ore: "19.00 - 20.00", evento: "Aperitivo"},
    {ore: "20.00 - 00.00", evento: "Cena di Gala e DJSet"},
]
